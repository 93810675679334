.Home {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    grid-gap: 15px;
}
.home-epic-free {
    background-color: #2A2A2A;
    padding: 15px;
    margin-top: 50px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: min(950px, 85%);
    
}
.epic-cards {
    grid-gap: 10px;
    display: flex;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.epic-cards div {
    width: min(450px, 90%);
    background-color: #1d1d1d;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.5s;
}
.epic-cards div:hover {
    background-color: #353535;
}
.epic-cards h4 {
    margin: 0;
    width: 100%;
    text-align: center;
    background-color: #D43A3A;
}
.epic-cards div img {
    width: 100%;
    margin: 0;
}
.epic-cards h5, h2 {
    padding: 5px;
}

.home-news {
    background-color: #2A2A2A;
    padding: 15px;
    border-radius: 15px;
    width: 950px;
}
.news-card {
    width: 950px;
    height: 250px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    background-color: #1d1d1d;
    border-radius: 15px;
}
    
.news-title { 
    grid-area: 1 / 1 / 2 / 13; 
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.news-img { 
    grid-area: 2 / 1 / 7 / 6; 
    display: flex;
    align-items: flex-start;
}
.news-img img {
    height: 100%;
    border-bottom-left-radius: 15px;
}
.news-description { 
    grid-area: 2 / 6 / 5 / 13; 
}
.news-type { 
    grid-area: 5 / 10 / 7 / 13; 
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 5px;
    margin: 0;
}
.news-type h5, p {
    margin: 0;
}
.news-links { 
    grid-area: 5 / 7 / 7 / 10; 
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-gap: 7px;
}
.news-links a {
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
}
.news-links a:hover {
    color: #D43A3A;
}
.news-links p {
    margin: 0;
    font-size: 15px;
}

.news-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    height: 25px;
}
.news-footer  a{
    text-decoration: none;
    transition: 0.5s;
    cursor: pointer;
    color: #fff;
}
.news-footer a:hover{
    text-decoration:wavy underline;
    color: #D43A3A;
}
.no-news {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 950px) {
    .home-news {
        background-color: #2A2A2A;
        padding: 15px;
        border-radius: 15px;
        width: 85%;
    }
    .news-card {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #1d1d1d;
        border-radius: 15px;
        padding: 50px 0 50px 0;
        grid-gap: 15px;
    }
    .news-title { 
        display: flex;
        align-items: center;
        padding-left: 5px;
    }
    .news-img {
        display: flex;
        justify-content: center;
    }
    .news-img img {
        width: 80%;
    }
    .news-links { 
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        grid-gap: 7px;
    }
    .news-description { 
        text-align: center;
    }
  }
