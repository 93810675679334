.Notfound {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.Notfound button {
    border-radius: 5px;
    padding: 5px;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    display: flex;
    align-items: center;
}
.Notfound button:hover {
    transform: scale(1.05);
}
.Notfound img {
    width: min(500px, 95%);
}
