.soon {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
   background-color: transparent;
}
.soon-idioma {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    width: 100vw;
    padding: 5px 0 5px 0;
    color: #fff;
}
.soon-idioma select {
    background-color: #000;
    color: #fff;
    border: none;
}