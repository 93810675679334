.SearchPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 50px;
    grid-gap: 10px;
}

/* 2A2A2A  1d1d1d*/
.search-value {
    background-color: #1d1d1d;
    border-radius: 15px;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    grid-gap: 5px;
}
.search-value a {
    text-decoration: none;
    color: #D43A3A;
}
.search-value h4 {
    margin: 5px 0 5px 0;
    display: flex;
}
.search-value h4 div {
    background-color: #161616;
    margin-inline: 5px;
    display: flex;
    grid-gap: 5px;
    padding-inline: 5px;
}
.search-value h4 div svg {
   cursor: pointer;
   transition: 0.1s;
}
.search-value h4 div svg:hover {
    transform: scale(1.05);
 }
.search-results {
    background-color: #1d1d1d;
    width: 80vw;
    padding: 10px;
    border-radius: 15px;
    display: flex;
}
.search-price {
    display: flex;
    margin-top: 15px;
    grid-gap: 5px;
}
.search-price select {
    background-color: #2A2A2A;
    color: #fff;
    border-radius: 5px;
}
.search-filter {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #161616;
    padding: 2px;
    grid-gap: 2px;
    width: auto;
}
.filter-store {
    width: 150px;
    height: 100%;
    background-color: #2A2A2A;
    cursor: pointer;
}
.filter-store-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
}
.filter-store-text p {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}
.filter-store svg {
    width: 30%;
    font-size: 20px;
    height: 30px;
    transition: 0.1s;
    
}

.filter-store-options {
    display: none;
    background-color: #2A2A2A;
    position: relative;
    width: 150px;
    top: 15px;
    border-radius: 10px;
    flex-direction: column;
    padding-top:  5px;
}
.filter-store-options button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #fff;
    padding: 10px 0 10px 0;
}
.filter-store-options button:hover {
    background-color: #1d1d1d;
}
.filter-store-options-triangulo {
    width: 0; 
    height: 0; 
    transform: scale(0.45);
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #2A2A2A;
    position: absolute;
    top: -15px;
    left: 10px;
}
.result-error {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;    
}
.result-loading {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;    
}
.result-loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 6px;
    background:
      conic-gradient(from 135deg at top,#D43A3A 90deg, #0000 0) 0 calc(50% - 4px)/17px 8.5px,
      radial-gradient(farthest-side at bottom left,#0000 calc(100% - 6px),#D43A3A calc(100% - 5px) 99%,#0000) top right/50%  50% content-box content-box,
      radial-gradient(farthest-side at top        ,#0000 calc(100% - 6px),#D43A3A calc(100% - 5px) 99%,#0000) bottom   /100% 50% content-box content-box;
    background-repeat: no-repeat;
    animation:s1 2s infinite linear;
}
@keyframes s1{ 
    100%{transform: rotate(1turn)}
}
.results-steam {
    width: 100%;
}
.results-epic {
    width: 100%;
    padding-left: 10px;
}
.results-list {
    grid-gap: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.results-card {
    margin-bottom: 10px;
    width: 200px;
    border-radius: 5px;
    background-color: #2A2A2A;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.5s;
}
.results-card:hover {
    transform: translatey(-5px);
    box-shadow: 0px 2px 1px 0px #D43A3A;
}
    
.card-img { 
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-img img {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.card-title { 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.card-title h3 {
    margin: 2px;
}
.card-price { 
    height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.card-price p {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    margin: 0;
}