.bug {
    width: 100vw;
    height: calc(100vh - 55px);
    padding-top: 55px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.form {
    margin-top: 50px;
    padding: 15px;
    grid-gap: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.bug-legend {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}
.bug-form-user {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 25px;
    margin-top: 15px;
}

.bug-form-user input {
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    border: 1px solid #D43A3A;
}
.bug-form-user input::placeholder {
    color: #D43A3A;
}

.bug-form-bugType {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 10px;
}
.bug-form-bugType select {
    padding: 5px;
    outline: none;
    border: 1px solid #D43A3A;
    border-radius: 5px;
    color: #D43A3A;
}
.bug-form-bugType input {
    padding: 10px;
    outline: none;
    border: 1px solid #D43A3A;
    border-radius: 5px;
}
.bug-form-bugType input::placeholder {
    color: #D43A3A;
}
.bug-form-info {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bug-form-info textarea {
    resize: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #D43A3A;
    width: 300px;
    height: 200px;
}
.form button{
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #D43A3A;
    color: #D43A3A;
    cursor: pointer;
    transition: 0.5s;
}
.form button:hover{
   transform: scale(1.05);
}
.confirmBug {
    position: absolute;
    opacity: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #adadadc7;
    transition: 1s;
}
.confirmBugReturn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: #fff;
    border:1px solid #D43A3A;
    border-radius: 15px;
    width: 300px;
    height: 300px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.confirmBugReturn button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border: none;
    background-color: #D43A3A;
    cursor: pointer;
    border-radius: 10px;
}
.result-div  {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000;
}
.custom-loader {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 6px;
    background:
      conic-gradient(from 135deg at top,#D43A3A 90deg, #0000 0) 0 calc(50% - 4px)/17px 8.5px,
      radial-gradient(farthest-side at bottom left,#0000 calc(100% - 6px),#D43A3A calc(100% - 5px) 99%,#0000) top right/50%  50% content-box content-box,
      radial-gradient(farthest-side at top        ,#0000 calc(100% - 6px),#D43A3A calc(100% - 5px) 99%,#0000) bottom   /100% 50% content-box content-box;
    background-repeat: no-repeat;
    animation:s1 2s infinite linear;
  }
  
  @keyframes s1{ 
    100%{transform: rotate(1turn)}
  }